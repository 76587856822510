<template>
  <div id="recomended">
    <categoriesPlaceholder v-if="loading" />
		<!-- <Intersect v-if="loading" @enter="getRecommendations" >
    <categoriesPlaceholder  />
   </Intersect> -->
    <categorySlider
      v-if= "!loading &&
          recommendationsContentIds &&
          recommendationsContentIds.length > 0 &&
          sliderContents &&
          sliderContents.length > 0"
      :screen="screen"
      :contentList="sliderContents"
      :screenName="screenName"
      :playerInstance="playerInstance"
    ></categorySlider>
  </div>
</template>

<script>
import categoriesPlaceholder from "@/components/placeholders/categoriesPlaceholder.vue";
import categorySlider from "@/components/categorySlider/categorySlider.vue";
import { eventBus } from "@/eventBus";
import { mapGetters, mapActions } from "vuex";
import Intersect from 'vue-intersect'


export default {
  props: {
    screen: {
      type: Object,
    },
    id: {
      type: Number,
    },
    screenName: {
      type: String,
    },
    localLang: {
      type: String,
    },
    playerInstance: {
      type: Object,
    },
  },
  data() {
    return {
      contentList: false,
      showSlider: true,
      sliderContents: [],
      localDisplayLang: null,
			loading: true,
      pageNum: 1,
      recommendedListData: null,
      recommendationsContentIds: []
    };
  },
  computed: {
    ...mapGetters(["getToken", "currentBrowser", "subscriberid", "country"]),
  },
  watch: {
    // getToken(val) {
    //   if (val) {
    //     this.getRecommendations();
    //   }
    // },
    // subscriberid(val) {
    //   if (val) {
    //     this.getRecommendations();
    //   }
    // },
    // screen(val) {
    //   console.log("slider screen changed");
    //   this.getRecommendations();
    // },
  },
  created() {
    //console.log('INSIDE_RECOMMENDED_SLIDER', this.id, this.screen, this.screenName);
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });
  },
  mounted() {   
    this.getRecommendations();
   },
  methods: {
    ...mapActions(["recommendedList", "listContent"]),
     getRecommendations() {
      let payload = {
        country: this.country ? this.country.CountryCode : localStorage.getItem("currentUserCountry") ? JSON.parse(localStorage.getItem("currentUserCountry")).CountryCode : null,
      };
      this.recommendedList(payload).then((recommendedList) => {
        if(Array.isArray(recommendedList) && recommendedList.length > 0){
              this.recommendationsContentIds = recommendedList;
              this.recommendedListData = recommendedList;
              this.listRecommendationContent(recommendedList);
            }else {
              this.loading = false;
            }
        }).catch((err) => {
          this.loading = false;
          console.log('recommendedError', err);
        });
        this.loading = false;
     },
    listRecommendationContent(list) {
      const seriesObjIds = [];
      list.map((element) => {
        seriesObjIds.push(`"${element}"`);
      })
        const payload = {
            contentlist: "[" + seriesObjIds + "]",
          // contentlist: list,
          displaylanguage: this.localDisplayLang,
          page: this.pageNum
        };
        this.listContent(payload).then((data) => {
            let sliceList = data.data.data;
            let contentListArray = Array.isArray(payload.contentlist) ? payload.contentlist : JSON.parse(payload.contentlist);
            let orderedSliderContents = [];
            contentListArray.forEach((contentId) => {
            // Find the matching item in sliceList
            let matchedItem = sliceList.find(item => item.objectid === contentId);
            if (matchedItem) {
              orderedSliderContents.push(matchedItem);
            }
          });
            this.sliderContents = [...orderedSliderContents];

            this.loading = false;
            // this.paginationForRecommendation(data.data.totalcount, data.data.data);
          }).catch((err) => {
            console.log('top10Error', err);
          });
    },
    paginationForRecommendation (totalcount, data) {
      setTimeout(() => {
        let sliderControls = document.getElementById("controls-Recommended_For_You");
        let rightButton = sliderControls && sliderControls.children && sliderControls.children[1];
        rightButton && rightButton.addEventListener('click', (event) => {
          if(data.length < totalcount) {
            this.pageNum = this.pageNum + 1;
            this.listRecommendationContent(this.recommendedListData);
          }
        })
      }, 1500);
    }
  },
  components: {
		Intersect,
    categoriesPlaceholder,
    categorySlider,
  },
};
</script>

<style></style>