<template>
    <div id="top10Moment">
      <categoriesPlaceholder v-if="loading" />
          <!-- <Intersect v-if="loading" @enter="getTopTen" >
      <categoriesPlaceholder  />
     </Intersect> -->
      <categorySlider
        v-if= "!loading &&
            topTenContentIds &&
            topTenContentIds.length > 0 &&
            sliderContents &&
            sliderContents.length > 0"
        :screen="screen"
        :contentList="sliderContents"
        :screenName="screenName"
        :playerInstance="playerInstance"
      ></categorySlider>
    </div>
  </template>
  
  <script>
  import categoriesPlaceholder from "@/components/placeholders/categoriesPlaceholder.vue";
  import categorySlider from "@/components/categorySlider/categorySlider.vue";
  import { eventBus } from "@/eventBus";
  import { mapGetters, mapActions } from "vuex";
  import Intersect from 'vue-intersect'
  
  
  export default {
    props: {
      screen: {
        type: Object,
      },
      id: {
        type: Number,
      },
      screenName: {
        type: String,
      },
      localLang: {
        type: String,
      },
      playerInstance: {
        type: Object,
      },
    },
    data() {
      return {
        contentList: false,
        showSlider: true,
        sliderContents: [],
        localDisplayLang: null,
        isMpegRequired: false,
        loading: true,
        topTenContentIds: []
      };
    },
    computed: {
      ...mapGetters(["getToken", "currentBrowser", "subscriberid", "country"]),
    },
    watch: {
      // getToken(val) {
      //   if (val) {
      //     this.getTopTen();
      //   }
      // },
      // subscriberid(val) {
      //   if (val) {
      //     this.getTopTen();
      //   }
      // },
      // screen(val) {
      //   console.log("slider screen changed");
      //   this.getTopTen();
      // },
    },
    created() {
      //console.log('INSIDE_RECOMMENDED_SLIDER', this.id, this.screen, this.screenName)
      this.isMpegRequired = this.checkMpegRequired();
      this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
  
      //this.loadCategory();
  
      eventBus.$on("check-local-storage", (data) => {
        this.localDisplayLang = data;
        // this.loadCategory();
      });
      // eventBus.$on("bundle-item-response", (list) => {
      //   let sliceList = list.data.slice(0, 10);
      //   this.sliderContents = [...sliceList];
      //   this.loading = false;      
      //   console.log("Hello-Bhavani", this.sliderContents);
      // });
    },
    mounted() {
      this.getTopTen();
     },
    methods: {
      ...mapActions(["topTenRecommendList", "listContent"]),
      getTopTen() {
        let payload = {
          country: this.country ? this.country.CountryCode : localStorage.getItem("currentUserCountry") ? JSON.parse(localStorage.getItem("currentUserCountry")).CountryCode : null,
        };        
        this.topTenRecommendList(payload).then((topTenList) => {
          console.log("top10List", topTenList);
          if(Array.isArray(topTenList) && topTenList.length > 0){
            //alert("data there top ten")
            this.topTenContentIds = topTenList;
            this.listTopTenContent(topTenList);
          }else {
            this.loading = false;
          }
        }).catch((err) => {
          this.loading = false;
          console.log('top10Error', err);
        });
      },  
      listTopTenContent(list) {
        const seriesObjIds = [];
        list.map((element) => {
          seriesObjIds.push(`"${element}"`);
        })
        if (seriesObjIds && seriesObjIds.length > 0) {
          const payload = {
            contentlist: "[" + seriesObjIds + "]",
            displaylanguage: this.localDisplayLang,
          };
        this.listContent(payload)
        .then((data) => {
          console.log("JUST_DATA", data.data.data);
          
          // Retrieve the full list of items from the data
          let sliceList = data.data.data; 
          console.log("JUST_DATA-toprespids", payload.contentlist);
          console.log("JUST_DATA_SLICESD", sliceList);

          // Ensure payload.contentlist is an array
          let contentListArray = Array.isArray(payload.contentlist) ? payload.contentlist : JSON.parse(payload.contentlist);
          
          // Prepare an array for ordered contents
          let orderedSliderContents = [];
          
          // Iterate over contentListArray to maintain the same order
          contentListArray.forEach((contentId) => {
            // Find the matching item in sliceList
            let matchedItem = sliceList.find(item => item.objectid === contentId);
            if (matchedItem) {
              orderedSliderContents.push(matchedItem);
            }
          });

          // Assign the first 10 items from the ordered contents to sliderContents
          this.sliderContents = orderedSliderContents.slice(0, 10);

        })
        .catch((err) => {
          console.log('top10Error', err);
        });

            this.loading = false;
        }
      },
      checkMpegRequired() {
        let isMpegRequired = false;
        let videoTag = document.createElement("video");
        let platformOs = navigator.platform;
  
        if (videoTag.canPlayType("application/vnd.apple.mpegurl")) {
          if (platformOs.startsWith("Mac")) {
            isMpegRequired = true;
          }
        }
  
        return isMpegRequired;
      }
    },
    components: {
          Intersect,
      categoriesPlaceholder,
      categorySlider,
    },
  };
  </script>
  
  <style></style>